<template>
  <div>
    <div class="header">
      <div class="header_top">
        <img src="../../assets/invoice.png" alt />
        <span class="center">企业管理</span>
        <img src="../../assets/right.png" alt />
        <span class="center">企业列表</span>
      </div>

      <div style="display: flex; align-items: center">
        <div style="display: flex; align-items: center">
          <span class="seach_text">关键字:</span>
          <el-input
            placeholder="请输入公司名称"
            v-model="keywords"
            @input="loadData('seath')"
            size="mini"
            style="margin-right: 10px; width: 250px"
            prefix-icon="el-icon-search"
            clearable
          ></el-input>
        </div>
        <el-button style="margin-left:20px" type="primary" size="small" @click="addProject()">新建</el-button>
      </div>
    </div>
    <el-table :data="tableData" style="width: 100%">
      <el-table-column label="企业标号">
        <template slot-scope="scope">
          <span class="clickColor">{{ scope.row.code }}</span>
          <!-- @click="goDetail(scope.row.code)" -->
        </template>
      </el-table-column>
      <el-table-column label="管理员账号">
        <template slot-scope="scope">
          <span v-if="scope.row.user">{{ scope.row.user.username | scope.row.config.userPhone}}</span>
        </template>
      </el-table-column>
      <!-- <el-table-column label="管理员密码">
        <template slot-scope="scope">
          <span>
            <el-tooltip
              class="item"
              v-if="!scope.row.passShow"
              effect="dark"
              content="点击查看密码"
              placement="top-start"
            >
              <span @click="goShow(scope.row.code)">******</span>
            </el-tooltip>
            <span v-else>{{ scope.row.password }}</span>
          </span>
        </template>
      </el-table-column>-->
      <el-table-column label="企业名称">
        <template slot-scope="scope">
          <span>{{ scope.row.name }}</span>
        </template>
      </el-table-column>
      <el-table-column label="企业统一信用编码">
        <template slot-scope="scope">
          <span>{{ scope.row.idCardNumber }}</span>
        </template>
      </el-table-column>
      <el-table-column label="企业Logo">
        <template slot-scope="scope">
          <div style="display: flex; align-items: center" v-if="scope.row.config">
            <el-tooltip
              class="item"
              effect="dark"
              content="点击查看图片"
              placement="top-start"
              v-if="scope.row.config.logoUrl"
            >
              <i
                class="el-icon-picture-outline font-img"
                @click="showImg(scope.row.config.logoUrl, '查看照片')"
              ></i>
            </el-tooltip>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="联系人">
        <template slot-scope="scope">
          <span v-if="scope.row.config">{{ scope.row.config.username }}</span>
        </template>
      </el-table-column>
      <el-table-column label="联系方式">
        <template slot-scope="scope">
          <span v-if="scope.row.config">{{ scope.row.config.userPhone }}</span>
        </template>
      </el-table-column>
      <el-table-column label="接收通知手机号">
        <template slot-scope="scope">
          <span v-if="scope.row.config">{{ scope.row.config.smsPhone }}</span>
        </template>
      </el-table-column>
      <el-table-column label="接收通知邮箱">
        <template slot-scope="scope">
          <span v-if="scope.row.config">{{ scope.row.config.email }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button @click="goedit(scope.row.code)" type="text">编辑</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <span class="clickColor">共{{ total }}条</span>
      <span class="clickColor" style="margin:0 10px">每页{{ pageSize }}条</span>
      <el-pagination
        background
        :current-page.sync="pageNumber"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-size="pageSize"
        prev-text="上一页"
        next-text="下一页"
        layout="prev,pager, next,jumper"
        :total="total"
      ></el-pagination>
    </div>
    <el-dialog
      class="showImgDialog"
      width="680px"
      :center="true"
      :title="dialogTitle"
      :visible.sync="showImgVisible"
      :append-to-body="true"
    >
      <div class="imgBox">
        <img :src="dialogImgUrl" class="img-resource" alt />
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { api } from '../../api/base/index';
export const customerList = api()('enterprise.list.json');

export default {
  data () {
    return {
      tableData: [

      ],
      dialogTitle: '',
      keywords: '',
      showImgVisible: false,
      dialogImgUrl: '',
      pageSize: 10,
      pageNumber: 1,
      total: 0,
    }
  },
  created () {
    this.loadData()
  },
  methods: {
    showImg (img, title) {
      this.dialogImgUrl = img;
      this.dialogTitle = title;
      console.log(this.dialogImgUrl);
      this.showImgVisible = true;
    },
    addProject () {
      this.$router.push({
        path: '/system/project/add',
      });
    },
    handleSizeChange (val) {
      this.pageSize = val;
      this.loadData();
    },
    handleCurrentChange (val) {
      this.pageNumber = val;
      this.loadData();
    },
    loadData (seath) {
      if (seath) {
        this.pageNumber = 1;
      }
      const params = {
        pageSize: this.pageSize,
        pageNumber: this.pageNumber,
        keywords: this.keywords
      };
      customerList(params).then((res) => {
        for (let i = 0; i < res.list.length; i++) {
          res.list[i].passShow = false
        }
        this.tableData = res.list;
        console.log(this.tableData)
        this.total = res.total;
      });
    },
    goShow (code) {

      this.tableData.forEach(it => {
        if (it.code === code) {
          console.log(it)
          it.passShow = !it.passShow
        }
      })

    },
    goedit (code) {
      this.$router.push({
        path: '/system/project/add',
        query: { code },
      });
    },
    goDetail (code) {
      this.$router.push({
        path: '/system/project/detail',
        query: { code },
      });
    }
  },
}
</script>
<style lang="scss" scope>
.showImgDialog .imgBox {
  display: flex;
}
.img-resource {
  width: 300px;
  height: 250px;
  margin: 0 auto;
}
.font-img {
  font-size: 26px;
  cursor: pointer;
  color: cadetblue;
}
</style>